.popup-container {
  z-index: 2;
  position: fixed;
  bottom: 0;
  background: #ebecf5;
  border-radius: 1.875rem 1.875rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.575rem 1.875rem;
}
.popup-none {
  z-index: 2;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  border-radius: 1.875rem 1.875rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1.875rem;
  padding: 1.575rem 1.875rem;
}
.popup-container .main-question-container,
.popup-container .main-question-container .input-container {
  margin-top: 0;
}
.popup-container .footer-btn,
.popup-container .footer-btn .closeBtn {
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  width: 10.5rem;
  height: 3.25rem;
}

.popup-container .closeBtn-Fever {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  height: 3.25rem;
}
.popup-container .cancelBtn {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  width: 10.5rem;
  height: 3.25rem;
  border: 1px #0a2458 solid;
  background-color: #ffffff;
}
.popup-container .closeBtnFever {
  color: #ffffff;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  background-color: #0a2458;
  width: 22.5rem;
}

.popup-outer {
  height: 100%;
  top: 0;
}

.headline {
  width: 50px;
  height: 8px;
  border-radius: 6px;
  background: #c2c9d8;
  margin-bottom: 1.875rem;
}

.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  /* -webkit-animation-name: fadeIn; 
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s; */
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  animation: slideIn 0.3s ease-out;
}
.fadeout {
  animation: slideOut 0.3s ease-out;
}
.confirm-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.confirm-item {
  margin: 0 auto;
  border-radius: 1.875rem;
  bottom: unset !important;
  max-width: 30rem;
  height: 19.938rem;
  height: 18.387rem;
  width: 22rem;
}
.popup-btn-container {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  width: 100%;
}
.confirm-title {
  font-weight: 800 !important;
  text-align: center;
  margin-bottom: 0.75rem;
}
.confirm-subtitle {
  font-weight: 400 !important;
  text-align: center;
}
.fadeoutConfirmBox {
  animation: fadeOut 0.7s ease-out;
}
.button-popup {
  display: flex;
  flex-direction: row;
}
.button-popup-fever {
  color: white;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  width: 100%;
  max-width: 28rem;
}
@media (max-width: 896px) {
  .button-popup {
    align-items: center;
  }
}
@-webkit-keyframes slideIn {
  from {
    bottom: -600px;
  }
  to {
    bottom: 0;
  }
}

@keyframes slideIn {
  from {
    bottom: -600px;
  }
  to {
    bottom: 0;
  }
}

@-webkit-keyframes slideOut {
  from {
    bottom: 0px;
  }
  to {
    bottom: -600px;
  }
}

@keyframes slideOut {
  from {
    bottom: 0px;
  }
  to {
    bottom: -600px;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
