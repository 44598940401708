.containerStylesfirst {
  height: 8px;
  max-width: 30rem;
  background-color: rgba(50, 50, 50, 0.1);
  padding: 0;
  border-radius: 20px;
  margin-left: 20px;
  width: 100%;
  margin-right: 0.875rem;
}

.containerStyles {
  height: 8px;
  max-width: 30rem;
  background-color: rgba(50, 50, 50, 0.1);
  padding: 0;
  border-radius: 20px;
  margin-left: 12px;
}
.progress-icon-container {
  display: flex;
  align-items: center;
}

.progress-bar-wrapper {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .progress-icon-container {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .progress-icon-container {
    justify-content: center;
    margin-right: 11px;
  }
}

@media screen and (min-width: 800px) {
  .progress-icon-container {
    justify-content: center;
    margin-right: 62px;
  }
}

@media screen and (min-width: 900px) {
  .progress-icon-container {
    justify-content: center;
    margin-right: 152px;
  }
}
@media screen and (max-width: 550px) {
  .progress-icon-container {
    justify-content: center;
    margin-right: 0.1px;
  }
}

.filler-anim {
  transition: width 0.5s ease;
}
