.checkbox-container {
  display: flex;
  flex-direction: column;
  margin: 0.625rem 0;
}

.checkbox-container .checkitem-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:3.5rem;
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 0.625rem;
}

.checkbox-container .checkitem-container:last-child {
  margin-bottom: 0; /* Override margin-bottom for the last item */
}
.checkitem-container.checked {
  background-color: #69d2e7;
}

.checkbox-item {
  display: flex !important;
  align-items: center;
  height: 2.188rem;
}

.checkbox-item .form-check-input {
  width: 28px;
  height: 28px;
  margin-left: 1px;
  margin-top: 1px;
  background-color: #f0f2f5;
  border-radius: 50%;
  background-image: url("../../../assets/images/right.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 1.25rem 0.875rem;
  margin-right: 0.875rem;
}
.checkbox-item .form-check-input:hover {
  cursor: pointer;
}
.checkbox-item .form-check-input:checked {
  box-shadow: unset !important;
  background-color: #0a2458;
}

.form-check-input:focus {
  outline: 0;
  box-shadow: unset !important;
}
.btn-title span {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.btn-title-cancel span {
  color: #0a2458;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}
.select-icon {
  width: 40px;
  height: 40px;
  margin-right: 22px;
}
.select-icon img {
  width: 40px;
  height: 40px;
}
.checkbox-item .select-icon:hover {
  cursor: pointer;
}
.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}
.selected-text {
  height: 2.7rem;
  width: 6.25rem;
  font-size: 1.125rem !important;
  background: #0a2458;
  border-radius: 20px;
  color: #fff;
  min-width: 3.125rem;
  text-align: center;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.checkbox-item .form-check-input:checked[type="checkbox"] {
  background-image: url("../../../assets/images/right.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 1.25rem 0.875rem;
}
.form-check-input {
  border: none;
}
