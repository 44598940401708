.footerButton {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.875rem;
  gap: 0.5rem;
  width: 100%;
  max-width: 30rem;
  height: 3.25rem;
  margin: 0 auto;
  background-color: #0a2458;
  border: none;
  font-weight: 800 !important;
  color: #ffffff;
  border-radius: 11px;
  text-transform: none;
  font-family: "Inter", sans-serif;
}
/* FooterButton.css */
.footerButtonLabel {
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a2458;
}
.footerbuttonlable1 {
  color: #fff;
}
.submitLabel {
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a2458;
}
.footerButton:hover {
  cursor: pointer;
}
.footerButton:disabled {
  opacity: 0.5;
}

.disabledBtn {
  pointer-events: none;
  opacity: 0.5;
}
.submitButton {
  background: #69d2e7;
}
.closeBtn {
  width: 18.75rem;
  height: 3.25rem;
  word-wrap: break-word;
}
.closeBtnThanks {
  width: 11rem;
  height: 3.25rem;
  border-radius: 11px;
  border: 1px solid #0a2458;
  background: #fff;
}
.closeBtnExpiredLink {
  width: 19rem;
}
.thanksYouClass {
  color: red;
}

.noneCloseBtn {
  word-wrap: break-word;
  background: #ffffff;
  height: 3.25rem;
  border: 1px solid #0a2458;
}
.closeBtn-none {
  height: 4.375rem;
  top: 504px;
  left: 24px;
  border-radius: 20px;
  gap: 10px;
  color: #ffffff;
  word-wrap: break-word;
}
.insideButton {
  width: 9.375rem;
  height: 3rem;

  color: rgba(255, 255, 255, 0.5);
}
.next-btn {
  flex: 2;
}
/* .next-btn button{  
  border-radius: 0 20px 20px 0;
  
} */
.back-btn {
  flex: 0 !important;
  margin-right: 0.375rem !important;
}
.back-btn button {
  border-radius: 20px 0 0 20px;
}
