/*All the variables are defined here at root level*/
:root {
  --backgroundColor: #f5f6fb;
  --primaryColor: #292d30;
  --defaultColor: #3e3e4a;
  --teritaryColor: #047069;
  --primaryFont: "Inter", "Helvetica", "Arial", "sans-serif";
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--primaryColor);
  font-family: var(--primaryFont);
  font-style: normal;
}

body {
  cursor: default;
  background: var(--backgroundColor);
}
html {
  font-size: 100%;
}
.rotate-screen {
  display: none;
}

@media screen and (orientation: landscape) {
  .main-container {
    display: none;
  }
  .rotate-screen {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background: var(--backgroundColor);
    align-items: center;
    margin: auto;
    height: 100vh;
    justify-content: center;
    text-align: left;
  }
  .rotate-screen img {
    width: 13.125rem;
    margin-right: 1rem;
  }
  .rotate-screen p {
    width: 16.875rem;
  }
}

.question-title {
  font-size: 1.438rem;
  font-weight: 600;
  line-height: 1.875rem;
  color: #0a2458;
  letter-spacing: -0.46px;
  margin-bottom: 0.5rem;
}
.question-title-popup {
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a2458;
  margin-bottom: 1rem;
}
.card-title {
  line-height: 3.563rem;

  color: #666;
  font-size: 1.125rem;
  font-family: "Inter", sans-serif;

  font-weight: 600;
  word-wrap: break-word;
}
.btn-title {
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 25.41px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a2458;
}
.btnTitleNone {
  color: #666666;
  font-size: 20px;
  font-family: "Inter", sans-serif;

  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.input-unit {
  font-family: "Inter", Arial, sans-serif;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 20px;
  margin-right: 8px;
}

.main-container {
  margin: 3.8rem auto;
  width: 100%;
  padding: 0 1.3125rem;
}
.progressbar-containerButton {
  justify-content: center;

  width: 100%;
  background: var(--backgroundColor);
  top: 0px;
  z-index: 1;
  position: fixed;
  height: 4rem;
  align-items: center;
}

.progressbar-container {
  justify-content: center;

  width: 100%;
  margin: 0;
  right: 0;
  background: var(--backgroundColor);
  padding: 1.7rem 1.3125rem 1.313rem 1.3125rem;
  top: 0px;
  z-index: 1;
  position: fixed;
}
.questionmain-container {
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  margin: 0 0 5rem 0;
  padding: 0;
}
.questionmain-container .carousel {
  max-width: 30rem;
  padding: 0;
}

.footer-btn {
  margin: 0;
  padding: 0;
  flex: 1;
}
.cancel-button {
  margin-right: 0.5rem;
}
.footer-main-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  margin: 0;
  right: 0;
  background: var(--backgroundColor);
  box-shadow: -4px 0px 8px rgb(0 0 0 / 10%);
  padding: 1.5rem 1.3125rem;
  z-index: 1;
}

.footer-container {
  display: flex;
  max-width: 30rem;
  padding: 0;
}

.keyboard-open {
  bottom: 150px;
}
.close-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100svh;
  align-items: center;
  justify-content: space-evenly;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
  transition: opacity 1.5s ease-out, transform 2s ease-out;
}
.close-page-container-ThanksYou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 0.938rem; */
  width: 100%;
  overflow: hidden;
}

.toast-msg {
  bottom: unset !important;
  top: 3rem;
  justify-content: center !important;
  left: 0 !important;
  right: 0 !important;
  width: 90%;
  max-width: 30rem;
  margin: 0 auto;
}
.toast-container {
  padding: 0.5rem;
  background: #ffeeee;
  border: 1px solid #fa3636;
  box-shadow: 0px 0.25rem 0.625rem rgb(0 0 0 / 15%);
  border-radius: 12px;
  display: flex;
  gap: 0.625rem;
  align-items: center;
}
.thank-you-container {
  border-radius: 7rem;
  width: 7.997rem;
  height: 7.997rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  text-align: center;
  color: blue;
  margin: 2rem 0rem;
}
.thank-you-container span {
  width: 80%;
}
.Thanks-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}
.Thanks-container-heading {
  width: 19.938rem;
  height: 2.125rem;
  color: #0a2458;
  text-align: center;
  font-family: "Inter", sans-serif;
  margin-bottom: 1rem;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.7px;
}
.Thanks-container-text {
  color: var(--Primary-Font-Color, #0a2458);
  text-align: center;
  font-family: "Inter", sans-serif;

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 1.2rem;
}
.Thanks-container-call {
  width: 17.75rem;
  color: var(--Grey2, #666);
  text-align: center;
  font-family: "Inter", sans-serif;
  margin-bottom: 0.4rem;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}
a.Thanks-container-call:hover {
  color: var(--Grey2, #666);
}

.callDataColor {
  color: var(--Primary-Font-Color, #0a2458);
  font-family: "Inter", sans-serif;

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.main-question-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 30rem;
  margin-bottom: 1.875rem;
}
.question-absolute-container {
  position: relative;
}
.question-container-popup {
  position: unset;
  height: unset;
}
.question-absolute-container .answer-section {
  width: 100%;
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .question-absolute-container .answer-section {
    margin-top: 160px;
  }
}

.question-absolute-container .answer-section-popup {
  position: unset;
  top: 0;
  margin: 0px;
  margin-bottom: 4.5rem;
}
.main-question-container .answer-multiselect {
  width: 100%;
  max-width: 30rem;
}
.invalid-token-message {
  overflow: hidden !important;
}
.pt-page-moveFromRightFade {
  -webkit-animation: moveFromRightFade 0.25s ease-in;
  animation: moveFromRightFade 0.25s ease-in;
}
.pt-page-moveToLeft {
  -webkit-animation: moveToLeft 0.25s ease-in;
  animation: moveToLeft 0.25s ease-in;
}
@-webkit-keyframes moveToLeft {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes moveToLeft {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moveFromRightFade {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  10% {
    opacity: 0.5;
    -webkit-transform: translateX(90%);
    transform: translateX(90%);
  }
  20% {
    opacity: 0.5;
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }
  30% {
    opacity: 0.5;
    -webkit-transform: translateX(70%);
    transform: translateX(70%);
  }
  40% {
    opacity: 0.5;
    -webkit-transform: translateX(60%);
    transform: translateX(60%);
  }
  50% {
    opacity: 0.5;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  60% {
    opacity: 0.6;
    -webkit-transform: translateX(40%);
    transform: translateX(40%);
  }
  70% {
    opacity: 0.7;
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
  }
  80% {
    opacity: 0.8;
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }
  90% {
    opacity: 0.9;
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes moveFromRightFade {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  10% {
    opacity: 0.5;
    -webkit-transform: translateX(90%);
    transform: translateX(90%);
  }
  20% {
    opacity: 0.5;
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }
  30% {
    opacity: 0.5;
    -webkit-transform: translateX(70%);
    transform: translateX(70%);
  }
  40% {
    opacity: 0.5;
    -webkit-transform: translateX(60%);
    transform: translateX(60%);
  }
  50% {
    opacity: 0.5;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  60% {
    opacity: 0.6;
    -webkit-transform: translateX(40%);
    transform: translateX(40%);
  }
  70% {
    opacity: 0.7;
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
  }
  80% {
    opacity: 0.8;
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }
  90% {
    opacity: 0.9;
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes moveFromLeftFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  10% {
    opacity: 0.1;
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%);
  }
  20% {
    opacity: 0.2;
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
  }
  30% {
    opacity: 0.3;
    -webkit-transform: translateX(-70%);
    transform: translateX(-70%);
  }
  40% {
    opacity: 0.4;
    -webkit-transform: translateX(-60%);
    transform: translateX(-60%);
  }
  50% {
    opacity: 0.5;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  60% {
    opacity: 0.6;
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);
  }
  70% {
    opacity: 0.7;
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
  }
  80% {
    opacity: 0.8;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
  }
  90% {
    opacity: 0.9;
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes moveFromLeftFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  10% {
    opacity: 0.1;
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%);
  }
  20% {
    opacity: 0.2;
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
  }
  30% {
    opacity: 0.3;
    -webkit-transform: translateX(-70%);
    transform: translateX(-70%);
  }
  40% {
    opacity: 0.4;
    -webkit-transform: translateX(-60%);
    transform: translateX(-60%);
  }
  50% {
    opacity: 0.5;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  60% {
    opacity: 0.6;
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);
  }
  70% {
    opacity: 0.7;
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
  }
  80% {
    opacity: 0.8;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
  }
  90% {
    opacity: 0.9;
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.carousel-previous {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  animation: moveFromLeftFade 0.25s ease-in;
  transition: opacity 0.2s ease-out !important;
}

.carousel-previous.active,
.carousel-next.active {
  display: block;
}

.carousel-next {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  animation: moveFromRightFade 0.25s ease-in;
  transition: opacity 0.2s ease-out !important;
}

.carousel-fade .carousel-item {
  opacity: inherit;
  transition: opacity 0.2s ease-out;
  transform: inherit;
}

.carousel-fade .carousel-item.active {
  transition: opacity 0.2s ease-out;
  z-index: unset;
}
.error-text {
  font-style: normal;
  color: #fa3636;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  height: 1.375rem;
}
.error-text-values {
  font-weight: 700;
  color: #fa3636;
}
.error-input {
  border: 3px solid #fa3636;
}
.expiredIcon {
  height: 6.25rem;
  width: 6.25rem;
}
.link-Thanks {
  background-color: white;
  justify-content: center;
}
.fade-out {
  opacity: 0;
  transform: translateY(20px);
}
.headingExpireShow {
  color: #0a2458;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.46px;
}
.TokenValidTiming-container {
  width: 18.25rem;
  height: auto;
  margin-bottom: 0.625rem;
}
.TokenValidTiming {
  color: var(--Grey2, #666);
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 1.25rem;
}
.TopSection{
  height: 100vh;
  position: fixed;
  background: #F5F6FB;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100svh;
  left: 0;
}
.Heading{
  color: #0A2458;
text-align: center;
font-size: 1.4375rem;
font-style: normal;
font-weight: 700;
line-height: 2.125rem; 
letter-spacing: -0.02875rem;
margin-top: 1.625rem;
}
.BottomText{
  color: var(--Grey2, #666);
font-size: 1.0625rem;
font-style: normal;
font-weight: 500;
line-height: 1.75rem;
padding-bottom: 1.62rem;
}
.SubHeading{
  color: var(--Grey2, #666);
text-align: center;
font-size: 1.0625rem;
font-style: normal;
font-weight: 400;
line-height: 1.75rem; 
padding:0.75rem 2.89rem 1.625rem 2.19rem;
}

.feedback-content-div {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  transition: opacity 1.5s ease-out, transform 2s ease-out;
  padding-bottom: 0.625rem;
}

.feedback-content {
  width: 19.125rem;
  height: 2.5rem;
  margin-bottom: 0.9375rem;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: center;
  color: #666666;
}

.text-area {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.text-area span {
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2.125rem; 
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 0.625rem; 
}

.text-area textarea {
  width: 21.25rem; 
  height: 7.875rem; 
  padding: 1.25rem; 
  border-radius: 0.625rem; 
  border: 0.0625rem solid #657396; 
  gap: 0.625rem; 
}

.text-area textarea:focus{
  outline: 1px solid #005FF2 !important;
}

.feedback-container {
  width: 90%;
  min-width: 24.375rem; 
  background: #F5F7FA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.response-container {
  width: 17.5rem; 
  min-height: 8.375rem; 
  margin: 1.25rem 0; 
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 1.5625rem;
}

.heading {
  font-family: "Inter", sans-serif;
  font-size: 1.125rem; 
  font-weight: 700;
  line-height: 1.75rem; 
  letter-spacing: -0.02em;
  text-align: left;
}

.response-div {
  height: 4.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5625rem; 
}

.star-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
}

.response-text {
  font-family: "Inter", sans-serif;
  font-size: 1rem; 
  font-weight: 700;
  line-height: 0.8125rem;
  letter-spacing: -0.02em;
  text-align: center;
}

.error-message{
  position: relative;
}

.error-message button{
  margin-top: 2rem;
}

.error-message>span{
  color: rgb(255, 76, 76);
  position: absolute;
  font-size: 0.75rem;
  top: 0.5rem;
  left: 2rem;
}

.error-message .hidden-element {
  visibility: hidden;
}

.filledStar svg path{
  fill: #FFA800;
}

@media screen and (min-width: 425px){
  .feedback-container{
    border-radius: 0.9375rem;
  }
}

.experience-secondary-btn{
  background: transparent !important;
  border: 1px solid #0a2458 !important;
  color: #0a2458 !important;
}