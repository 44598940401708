.radio-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.radio-item {
  display: flex !important;
  align-items: center;
  border-radius: 20px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 0.625rem;
  padding: 1.25rem;
}
.radio-container .radio-item:last-child {
  margin-bottom: 0; /* Override margin-bottom for the last item */
}

.radio-item .form-check-input {
  background: #ffffff;
  border: 1.5px solid #c2c9d8;
  width: 1.875rem;
  height: 1.875rem;
  margin: 1px;
  margin-right: 1.25rem;
  flex: 1;
}

.radio-item .form-check-label {
  width: calc(100% - 1.875rem - 1.25rem); /* Adjust the max-width accordingly */
}

.radio-item .form-check-input:hover {
  cursor: pointer;
}
.radio-item .form-check-input:checked {
  box-shadow: unset !important;
}

.form-check-input:focus {
  outline: 0;
  box-shadow: unset !important;
}

.radio-container .form-check-input:checked + .radio-item {
  background-color: #007bff;
  color: #fff;
}

.radio-item .select-icon img {
  width: 2.188rem;
  height: 2.188rem;
}
.radio-item .form-check-input:checked[type="radio"] {
  background-image: url("../../../assets/images/Icon.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border: none;
}

.radio-item.radio-item-color-selected {
  background-color: #69d2e7;
  color: white;
}

.radio-item.radio-item-color-notselected {
  background-color: #ffffff;
}
