.input-main-container {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
}
.input-main-containerWeight {
  margin-top: 4.5rem;
  margin-bottom: 1.25rem;
  height: 10.2rem;
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}
.inputBox {
  width: 100%;
  height: 70px;
  background: #ffffff;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.125rem;
  gap: 0.625rem;
  border: 2px solid #3e3e4a;
}

.inputBox1 {
  width: 100%;
  height: 70px;
  background: #ffffff;
  border: none;
  border-radius: 0.313rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.125rem;
  gap: 0.625rem;
}

.inputBox:hover {
  cursor: pointer;
}
.inputBox:focus {
  outline: none;

  border: 2px solid #3e3e4a;
}
.inputBox1:focus {
  outline: none;

  border: 3px solid #8a86aa;
}

.unitItem {
  position: absolute;
  max-width: -moz-fit-content;
  display: flex;
  right: 5px;
  height: 4.375rem;
  align-items: center;
  margin-top: 0;
}
.error-input {
  outline: none !important;
  border: 3px solid #fa3636 !important;
  background: #ffeeee !important;
}
.error-container{
  display: block;
  height:1rem;
}
.hidden {
  display: inline-block;
  height: 1.375rem;
}
/* .input[type="text"]:focus {
  background-color: pink;
  } */
